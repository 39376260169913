export const useHeader = () => {
  const isHeaderSearchFull = useState('isHeaderSearchFull', () => false);
  const showCatalogMenu = useState('showCatalogMenu', () => false);
  const isHeaderSearchMobileDialog = useState('isHeaderSearchMobileDialog', () => false);
  const isNotificationDialog = useState('isNotificationDialog', () => false);

  const { isMounted } = useUiBreakpoints();

  const headerHeight = computed(() => {
    if (!isMounted.value) return 0;

    const header: HTMLElement | null = document.querySelector('.osk-header');

    return header?.offsetHeight ?? 0;
  });

  return {
    isHeaderSearchFull,
    showCatalogMenu,
    isHeaderSearchMobileDialog,
    isNotificationDialog,
    headerHeight,
  };
};
